
import { computed, defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'
import { useRoute } from 'vue-router'
import type { ComposeNavButton } from '@/definitions/compose/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  name: 'Compose',
  components: { TmAlert, WhiteBlock, PageContent, TmWatchTutorial },
  setup() {
    const { isSmMax } = useBreakpoints()
    const route = useRoute()
    const title = computed(() => route.meta.title)
    const navButtons: ComposeNavButton[] = [
      { label: 'Text message', icon: 'chat', link: 'base.compose.message' },
      { label: 'Text to speech', icon: 'record_voice_over', link: 'base.compose.speech' },
      { label: 'Voice broadcast', icon: 'phone_forwarded', link: 'base.compose.broadcast' },
    ]
    const activeNavButton = computed(() => navButtons.find((el) => el.link === route.name))

    return {
      isSmMax,
      title,
      navButtons,
      activeNavButton,
    }
  },
})
